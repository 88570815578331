import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import { decrypt } from 'src/helpers/constants';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user, logout } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const activeEvent = localStorage.getItem('arsh:activeevent');

  const loggedInUserEvent = decrypt(user?.c);

  if (!isAuthenticated && pathname.includes('client/app')) {
    return <Navigate to={`/client/login`} />;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/${activeEvent}/login`} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={`/${activeEvent}/login`} />;
  } else if (isAuthenticated && user?.userType === 'exhibitor' && pathname.includes('/admin')) {
    return <Navigate to={`/exhibitor-dashboard/home`} />;
  } else if (isAuthenticated && user?.userType === 'delegate' && pathname.includes('/admin')) {
    return <Navigate to={`/${activeEvent}/event`} />;
  } else if (isAuthenticated && user?.userType === 'client') {
    return <Navigate to={`/client/app`} />;
  }
  // else if (isAuthenticated && loggedInUserEvent !== activeEvent) {
  //   alert('You have been logged out of ' + loggedInUserEvent + ' so as to visit ' + activeEvent);
  //   logout();
  //   return <Navigate to={`/${activeEvent}`} />;
  // }
  else {
    return <>{children}</>;
  }
  // else if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }
}
