// import jwtDecode from 'jwt-decode';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  // const decoded = jwtDecode(accessToken);
  // const currentTime = Date.now() / 1000;

  // return decoded.exp > currentTime;
  return true;
};

const setSession = (accessToken, user) => {
  console.log(user);
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('arsh:user', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('arsh:user');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
