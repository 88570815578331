import { Grid, Hidden } from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { styled } from '@mui/material/styles';
import { SideBar } from './SideBar/SideBar';
import cssStyles from '../../utils/cssStyles';
import { useGetData } from 'src/api/requests';
import { endpoints } from 'src/api/endpoints';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { useTheme } from '@emotion/react';
import { decrypt } from 'src/helpers/constants';
import useSettings from 'src/hooks/useSettings';

export const EventContainer = ({ children, type, ...others }) => {
  const activeEvent = localStorage.getItem('arsh:activeevent');
  const [callSideBar, setcallSideBar] = useState(false);
  const [ShowChat, setShowChat] = useState(false);
  const mdUp = useResponsive('up', 'md');

  const { onChangeMode2 } = useSettings();

  useEffect(() => {
    setTimeout(() => {
      onChangeMode2('dark');
    }, 2000);
  }, []);

  return (
    <>
      {!mdUp && (
        <Iconify
          onClick={() => setcallSideBar(!callSideBar)}
          style={{
            opacity: '0.8',
            position: 'fixed',
            cursor: 'pointer',
            right: -5,
            top: 33,
            zIndex: 999999,
            fontSize: 31,
          }}
          icon="clarity:bars-line"
        />
      )}
      {(mdUp || callSideBar) && <SideBar {...others} />}
      {callSideBar && (
        <div
          onClick={() => setcallSideBar(false)}
          style={{
            background: 'rgba(0,0,0,.1)',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            position: 'fixed',
            zIndex: 9,
          }}
        ></div>
      )}
      {type === 'grid' ? (
        <Grid
          container
          spacing={4}
          sx={{
            filter: callSideBar ? 'blur(5px)' : 'unset',
            width: mdUp ? '90%' : '110%',
            mr: 'auto',
            mt: mdUp ? 0 : 2,
          }}
        >
          {children}
        </Grid>
      ) : (
        <div
          spacing={4}
          style={{
            filter: callSideBar ? 'blur(5px)' : 'unset',
            width: mdUp ? '90%' : '100%',
            marginRight: 'auto',
            marginTop: mdUp ? 0 : '70px',
          }}
        >
          {children}
        </div>
      )}
      <AnnouncementsMarquee activeEvent={activeEvent} />
    </>
  );
};

const Marquee = styled('div')(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  width: '100%',
  fontSize: 13,
  background: 'rgba(0, 0, 0, 0.3)',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  color: '#909eab',
  paddingTop: 5,
  height: 35,
  zIndex: 99,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

const AnnouncementsMarquee = ({ activeEvent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const loggedAnnouncements = localStorage.getItem('arsh:loggedannouncements');

  // const { isError, isLoading, data } = useGetData(
  //   'AnnouncementsList',
  //   endpoints?.getannouncements + '?event=' + activeEvent,
  //   { refetchInterval: 30000 }
  // );

  const data = JSON.parse(localStorage.getItem('arsh:announcements'));

  if (data?.rows && loggedAnnouncements !== JSON.stringify(data?.rows)) {
    console.log('nope', loggedAnnouncements, JSON.stringify(data?.rows));
    //Show alert
    enqueueSnackbar(data?.rows[0]?.title, { variant: 'info', autoHideDuration: 10000 });
    localStorage.setItem('arsh:loggedannouncements', JSON.stringify(data?.rows));
  }
  return data ? (
    <Marquee>
      <marquee>
        {data?.rows.map((row, index) =>
          row?.link ? (
            <a
              key={row?.id}
              style={{ color: 'inherit', paddingRight: '100px' }}
              rel="noreferrer"
              target="_blank"
              href={row?.link}
            >
              {row?.title}
            </a>
          ) : (
            <span key={row?.id} style={{ paddingRight: '100px' }}>
              {row?.title}
            </span>
          )
        )}
      </marquee>
    </Marquee>
  ) : null;
};

export const ChatBox = ({ setShowChat, activeEvent }) => {
  const { user } = useAuth();
  const theme = useTheme();

  return (
    <>
      <div
        className="animated fadeIn"
        onClick={() => setShowChat(false)}
        style={{
          background: 'rgba(0,0,0,.5)',
          height: '100vh',
          width: '100%',
          top: 0,
          left: 0,
          cursor: 'pointer',
          position: 'fixed',
          zIndex: 99999,
          animationDuration: '0.2s',
        }}
      ></div>
      <iframe
        style={{
          width: 400,
          zIndex: 9999999,
          height: '95vh',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 20,
          border: 0,
          background: '#212b36',
          borderRadius: 10,
          overflow: 'hidden',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow: '0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%)',
        }}
        title="ARSH CHAT"
        scrolling="no"
        src={`https://emeraldkwekowe.com/arsh-assets/chat-service/delegate/?name=${decrypt(
          user?.first_name
        )}&email=${decrypt(user?.email)}&id=${user?.id}&color=${
          theme?.palette?.primary?.main?.replace('#', '') || '3399cc'
        }&event=${activeEvent}`}
      >
        Loading chat
      </iframe>
    </>
  );
};
