import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import ClientGuestGuard from '../guards/ClientGuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import DashboardLayout from '../layouts/dashboard';
import ClientLayout from '../layouts/client';
import AuthGuard from '../guards/AuthGuard';
import ClientAuthGuard from '../guards/ClientAuthGuard';

import EventLayout from 'src/layouts/event';
import ComingSoon from 'src/pages/Home/ComingSoon';
import Settings from '../components/settings';
import NoReg from 'src/pages/Home/NoReg';
import EventExpired from 'src/pages/Error/EventExpired';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const { origin, protocol } = window.location;
  if (origin.includes('3030')) {
  } else {
    if (protocol !== 'https:') {
      const direction = 'https://arsh.events' + pathname;
      window.location.href = direction;
    }
  }
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/admin')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    //ADMIN ROUTES
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <Settings />
          <DashboardLayout type="event" />
        </AuthGuard>
      ),
      children: [
        { path: '/admin', element: <Navigate to="/admin/dashboard" replace />, index: true },
        { path: '/admin/dashboard', element: <GeneralApp /> },
        { path: '/admin/edit-profile', element: <AdminEditProfile /> },
        { path: '/admin/qr-scans', element: <TrafficControl /> },
        { path: '/admin/generate-tags', element: <EventTags /> },
        { path: '/admin/live-chat', element: <AdminLiveChat /> },
        {
          path: 'analytics',
          children: [
            { element: <Navigate to="/admin/analytics/" replace />, index: true },
            { path: 'registrants', element: <UserList /> },
            { path: 'participants', element: <Participants /> },
            { path: 'traffic-control', element: <TrafficControl /> },
            { path: 'speakers', element: <Speakers /> },
          ],
        },
        { path: 'live-voting', element: <Voting /> },
        {
          path: 'event-settings',
          children: [
            { element: <Navigate to="/admin/event-settings/" replace />, index: true },
            { path: 'defaults', element: <Default /> },
            { path: 'email', element: <Email /> },
            { path: 'packages', element: <EditPackages /> },
            { path: 'program', element: <ProgramOfEvents /> },
            { path: 'reg-fields', element: <RegistrationFields /> },
          ],
        },
        {
          path: 'delegate-settings',
          children: [
            {
              path: '/admin/delegate-settings',
              element: <Navigate to="/admin/delegate-settings/delegate-codes" replace />,
              index: true,
            },
            { path: 'delegate-codes', element: <DelegateCodes /> },
            { path: 'add-delegate', element: <AddDelegete /> },

            { path: 'privileged-delegates', element: <PrivilegedDelegates /> },
            { path: 'delegate-types', element: <DelegateTypes /> },
          ],
        },
        {
          path: 'sessions',
          children: [
            { path: '/admin/sessions/', element: <Navigate to="/admin/sessions/add" replace />, index: true },
            {
              path: 'add',
              element: <NewSession />,
            },
            {
              path: 'view',
              element: <ViewSessions />,
            },
            {
              path: 'modify',
              element: <ViewSessions />,
            },
          ],
        },

        {
          path: 'administrators',
          element: <ManageAdministrators />,
        },
        {
          path: 'announcements',
          element: <Announcements />,
        },
        {
          path: 'activity',
          element: <ActivityLog />,
        },
        {
          path: 'exhibitors',
          children: [
            { element: <Navigate to="/admin/exhibitors/" replace />, index: true },
            { path: 'add', element: <AddExhibitor /> },
            { path: 'view-exhibitors', element: <ManageExhibitors /> },
          ],
        },
        {
          path: 'sponsors',
          element: <ManageSponsors />,
        },
        { path: '404', element: <ComingSoon /> },
        { path: '*', element: <ComingSoon /> },
      ],
    },

    {
      path: '/exhibitor-dashboard',
      element: (
        <AuthGuard>
          <Settings />
          <DashboardLayout type="exhibitor" />
        </AuthGuard>
      ),
      children: [
        {
          path: '/exhibitor-dashboard',
          element: <Navigate to="/exhibitor-dashboard/home" replace />,
          index: true,
        },
        { path: 'home', element: <ExhibitorGeneralApp /> },
        { path: 'analytics', element: <Navigate to="/exhibitor-dashboard/analytics/visits" /> },
        { path: 'analytics/virtual-visits', element: <BoothVisits /> },
        { path: 'analytics/physical-visits', element: <BoothVisitsPhysical /> },
        { path: 'analytics/ratings', element: <BoothRatings /> },
        { path: 'analytics/votes', element: <BoothVotes /> },
        { path: 'edit-details', element: <EditExhibitorDetails /> },
        { path: 'live-voting', element: <Voting /> },
        { path: 'live-chat', element: <ExhibitorLiveChat /> },
        { path: 'zoom', element: <AddZoomSession /> },
        { path: '404', element: <ComingSoon /> },
        { path: '*', element: <ComingSoon /> },
      ],
    },

    {
      path: '/exhibitor-dashboard/edit-booth',
      element: <Exhibitor />,
    },

    {
      path: '/admin/edit-booth',
      element: <Exhibitor />,
    },

    // MAIN EVENT Routes

    {
      path: '/:event/event',
      element: (
        <AuthGuard>
          <EventLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/:event/event',
          element: <MainEvent />,
        },
        {
          path: 'live',
          element: <LiveSessions />,
        },

        {
          path: 'vip',
          element: <VIPLounge />,
        },
        {
          path: 'networking',
          element: <NetworkingLounge />,
        },
        {
          path: 'exhibitors',
          element: <Exhibitors />,
        },
      ],
    },

    //EXHIBITORS PATH
    {
      path: '/:event/event/exhibitors/:exhibitor',
      element: <Exhibitor />,
    },

    //LIVE SESSIONS ZOOM ROUTE
    {
      path: '/:event/event/live/:session/zoom',
      element: <ZoomV2 />, //change to <ZoomV2 />
    },
    //APP SCREENS
    {
      path: 'app/live/zoom',
      element: <MobileAppZoom />,
    },
    {
      path: 'app/live/exit',
      element: <MobileAppZoom />,
    },

    //LIVE SESSIONS OTHER ROUTES
    {
      path: '/:event/event/live',
      children: [
        {
          path: ':session',
          element: (
            <AuthGuard>
              <Session />
            </AuthGuard>
          ),
        },
        {
          path: ':session/:type',
          element: (
            <AuthGuard>
              <Session />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      path: '/:event/event/chat/video',
      element: <Video />,
    },

    //EVENT AUTH/IDENTIFIER ROUTES
    {
      path: '/:event',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: '/:event',
          element: <EventLanding />,
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Settings />
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <Settings />
              <ResetPassword />
            </GuestGuard>
          ),
        },

        {
          path: 'admin',
          element: <AdminLanding />,
        },
        {
          path: 'check-in',
          element: <CheckIn />,
        },
        {
          path: 'register/check-in',
          element: <CheckIn />,
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Settings />

              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register/modify',
          element: (
            <GuestGuard>
              <Settings />
              <ModifyPackage />
            </GuestGuard>
          ),
        },
        {
          path: 'register/speaker',
          element: (
            <GuestGuard>
              <Settings />
              <SpeakerRegistration />
            </GuestGuard>
          ),
        },
        {
          path: 'register/exhibitor',
          element: (
            <GuestGuard>
              <Settings />

              <ExhibitorRegistration />
            </GuestGuard>
          ),
        },
        {
          path: 'uploads/sponsor',
          element: (
            <GuestGuard>
              <Settings />

              <SponsorRegistration />
            </GuestGuard>
          ),
        },
        {
          path: 'register/sponsor',
          element: (
            <GuestGuard>
              <Settings />

              <SponsorRegistration />
            </GuestGuard>
          ),
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> },
      ],
    },

    // Client Routes
    {
      path: '/client',
      children: [
        {
          path: 'login',
          element: (
            <ClientGuestGuard>
              <ClientLogin />
            </ClientGuestGuard>
          ),
        },
      ],
    },

    {
      path: '/client/app',
      element: (
        <ClientAuthGuard>
          <Settings />
          <ClientLayout />
        </ClientAuthGuard>
      ),
      children: [
        { path: '/client/app', element: <Navigate to="/client/app/dashboard" replace />, index: true },
        { path: '/client/app/dashboard', element: <ClientDashboard /> },
        { path: '/client/app/event/new', element: <AddEvent /> },
        { path: '/client/app/events', element: <MyEvents /> },
        { path: '/client/app/profile', element: <ClientProfile /> },
        { path: '/client/app/subscriptions', element: <Subscriptions /> },
      ],
    },

    // Error Routes

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <SelectEvent /> },
        { path: '*', element: <SelectEvent /> },
      ],
    },

    //QR Routes
    {
      path: 'qr',
      children: [
        { path: '/qr', element: <Navigate to="/qr/read" /> },
        { path: 'read', element: <ReadQR /> },
        { path: 'generate', element: <GenerateQR /> },
        { path: 'self-print', element: <SelfGenerate /> },
        { path: 'self-print/select', element: <SelectPrintType /> },
      ],
    },

    // Website Routes
    { path: 'select-event', element: <SelectEvent /> },
    { path: 'coming-soon', element: <ComingSoon /> },
    { path: '/:event/no-reg', element: <NoReg /> },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <>
              <HomePage />
            </>
          ),
          index: true,
        },

        {
          path: 'payment',
          element: (
            // <ClientGuestGuard>
            <Payment />
            // </ClientGuestGuard>
          ),
        },
        { path: 'contact', element: <Contact /> },
        { path: 'faq', element: <Faqs /> },
        { path: 'compare-plans', element: <Pricing /> },
        { path: '404', element: <SelectEvent /> },
        { path: 'expired', element: <EventExpired /> },
        { path: '*', element: <SelectEvent /> },
      ],
    },

    { path: '*', element: <SelectEvent /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));
const Register = Loadable(lazy(() => import('../pages/Register')));
const SpeakerRegistration = Loadable(lazy(() => import('../pages/Register/SpeakerRegistration')));
const ModifyPackage = Loadable(lazy(() => import('../pages/Register/ModifyPackage')));
const ExhibitorRegistration = Loadable(lazy(() => import('../pages/Register/Exhibitor')));
const SponsorRegistration = Loadable(lazy(() => import('../pages/Register/Sponsorship')));
const CheckIn = Loadable(lazy(() => import('../pages/CheckIn')));

//EVENT PAGES
const EventLanding = Loadable(lazy(() => import('../pages/EventLanding')));

//CLIENT PAGES
const ClientLogin = Loadable(lazy(() => import('../pages/Client/Login')));
const AddEvent = Loadable(lazy(() => import('../pages/Client/App/AddEvent/AddEvent')));
const ClientDashboard = Loadable(lazy(() => import('../pages/Client/App/Dashboard/Dashboard')));
const MyEvents = Loadable(lazy(() => import('../pages/Client/App/Events/Events')));
const ClientProfile = Loadable(lazy(() => import('../pages/Client/App/Profile/Profile')));
const Subscriptions = Loadable(lazy(() => import('../pages/Client/App/Subscriptions/Subscriptions')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home/Home')));
const Pricing = Loadable(lazy(() => import('../pages/Home/Pricing')));
const Contact = Loadable(lazy(() => import('../pages/Home/Contact')));
const Payment = Loadable(lazy(() => import('../pages/Home/Payment')));
const Faqs = Loadable(lazy(() => import('../pages/Home/Faqs')));

// ERROR
const Page500 = Loadable(lazy(() => import('../pages/Error/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Error/Page404')));
const SelectEvent = Loadable(lazy(() => import('../pages/Event/SelectEvent/SelectEvent')));

//ADMIN
const GeneralApp = Loadable(lazy(() => import('../pages/Admin/GeneralApp')));
const UserList = Loadable(lazy(() => import('../pages/Admin/Analytics/Registrants')));
const Participants = Loadable(lazy(() => import('../pages/Admin/Analytics/Participants')));
const TrafficControl = Loadable(lazy(() => import('../pages/Admin/Analytics/TrafficControl')));
const AdminLanding = Loadable(lazy(() => import('../pages/Admin/AdminLanding')));
const Speakers = Loadable(lazy(() => import('../pages/Admin/Analytics/Speakers')));
const EventTags = Loadable(lazy(() => import('../pages/Admin/EventTags')));
//--settings
const Email = Loadable(lazy(() => import('../pages/Admin/Settings/Email')));
const Default = Loadable(lazy(() => import('../pages/Admin/Settings/Default')));
const EditPackages = Loadable(lazy(() => import('../pages/Admin/Settings/EditPackages')));
const ProgramOfEvents = Loadable(lazy(() => import('../pages/Admin/Settings/ProgramOfEvents')));
const Voting = Loadable(lazy(() => import('../pages/Admin/Voting')));
const RegistrationFields = Loadable(lazy(() => import('../pages/Admin/Settings/RegistrationFields')));

//-- degelate settings
const DelegateTypes = Loadable(lazy(() => import('../pages/Admin/DelegateSettings/DelegateTypes')));
const PrivilegedDelegates = Loadable(lazy(() => import('../pages/Admin/DelegateSettings/PrivilegedDelegates')));
const DelegateCodes = Loadable(lazy(() => import('../pages/Admin/DelegateSettings/DelegateCodes')));
const AddDelegete = Loadable(lazy(() => import('../pages/Admin/DelegateSettings/AddDelegete')));
// -- admins
const ManageAdministrators = Loadable(lazy(() => import('../pages/Admin/Administrators/ManageAdmins')));
const AdminEditProfile = Loadable(lazy(() => import('../pages/Admin/EditProfile')));
// -- exhibitors
const AddExhibitor = Loadable(lazy(() => import('../pages/Admin/Exhibitors/Add')));
const ManageExhibitors = Loadable(lazy(() => import('../pages/Admin/Exhibitors/ManageExhibitors')));
const ManageSponsors = Loadable(lazy(() => import('../pages/Admin/Sponsors')));
// -- announcements
const Announcements = Loadable(lazy(() => import('../pages/Admin/Announcements/Manage')));
// -- activity log
const ActivityLog = Loadable(lazy(() => import('../pages/Admin/ActivityLog')));
//-- sessions
const NewSession = Loadable(lazy(() => import('../pages/Admin/Sessions/NewSession')));
const ViewSessions = Loadable(lazy(() => import('../pages/Admin/Sessions/ViewSessions')));
//-- chat
const AdminLiveChat = Loadable(lazy(() => import('../pages/Admin/Chat')));

//MAIN EVENT
const MainEvent = Loadable(lazy(() => import('../pages/Event')));
const LiveSessions = Loadable(lazy(() => import('../pages/Event/Live')));
const Session = Loadable(lazy(() => import('../pages/Event/Live/Session')));
const VIPLounge = Loadable(lazy(() => import('../pages/Event/VIPLounge')));
const NetworkingLounge = Loadable(lazy(() => import('../pages/Event/NetworkingLounge')));
const Exhibitors = Loadable(lazy(() => import('../pages/Event/Exhibitors')));
const Exhibitor = Loadable(lazy(() => import('../pages/Event/Exhibitors/Exhibitor')));
const ZoomV2 = Loadable(lazy(() => import('../pages/Event/Live/Session/components/ZoomV2')));
const Video = Loadable(lazy(() => import('../pages/Event/Chat/Video')));
const GenerateQR = Loadable(lazy(() => import('../pages/QR/GenerateQR')));
const SelfGenerate = Loadable(lazy(() => import('../pages/QR/SelfGenerate')));
const SelectPrintType = Loadable(lazy(() => import('../pages/QR/SelfGenerate/SelectPrintType')));
const ReadQR = Loadable(lazy(() => import('../pages/QR/Read')));

//EXHIBITOR ROUTES
const EditExhibitorDetails = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Edit')));
const ExhibitorGeneralApp = Loadable(lazy(() => import('../pages/ExhibitorAdmin/GeneralApp')));
const BoothVisits = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Analytics/BoothVisits')));
const BoothVisitsPhysical = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Analytics/BoothVisitsPhysical')));
const BoothRatings = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Analytics/BoothRatings')));
const BoothVotes = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Analytics/BoothVotes')));
const ExhibitorLiveChat = Loadable(lazy(() => import('../pages/ExhibitorAdmin/Chat')));
const AddZoomSession = Loadable(lazy(() => import('../pages/ExhibitorAdmin/AddZoom')));

//MOBILE APP
const MobileAppZoom = Loadable(lazy(() => import('../pages/Event/Live/Session/components/mobile_app/Zoom')));
